import { NavBar } from "../components/Navbar/NavBar";
import { Banner } from "../components/Banner/Banner";
import { ProcesosHome } from "../components/ProcesosHome/ProcesosHome";
import { Footer } from "../components/Footer/Footer";
import '../App.css'
import { FloatingButton } from "../components/FloatingButton/FloatingButton";
export function HomePage(){
    return (
        <>
        <div className="App">
            <NavBar/>
            <Banner/>
            <ProcesosHome />
            <FloatingButton/>
            <Footer />
        </div>
        </>
    )
}