import { Link } from "react-router-dom"

export const Footer = () =>{
    return(
        <div class="footer-dark">
        <footer>
            <div class="container">
                <div class="row">
                    <div class="col-md-4 mb-4 item text">
                    <h3>Business Legal Group</h3>
                        <p>Contamos con más 6 de años de experiencia en el mercado. Con resultados exitosos y clientes satisfechos.</p>
                    </div>
                    <div class="col-md-4 mb-md-0 item" >
                        <h3>Enlaces de Interes</h3>
                        <ul>
                            <li><a as={Link} to="/quienes-somos">¿Quienes somos?</a></li>
                        </ul>
                    </div>
                    <div class="col-md-4 text">
                    <h3>Contacto</h3>
                        <p>Bogota, Colombia</p>
                        <p>Carrera 18 A 50-50 sur</p>
                        <p>Whatsapp: 3227178172</p>
                        <p>Telefonos de Contacto</p>
                        <p>3227178172</p>
                        <p>3103719272</p>
                    </div>
                    <div class="col item social">
                        <a href="https://www.facebook.com/businesslegalgroup/" ><ion-icon name="logo-facebook"></ion-icon></a>
                        <a href="https://www.instagram.com/businesslegalgroup?igsh=cDdueDI4MHk2NG4w"><ion-icon name="logo-instagram"></ion-icon></a>
                    </div>
                </div>
                <p class="copyright">Business legal Group © 2024</p>
            </div>
        </footer>
    </div>
    )
}
