import { BannerConsumidor } from "../components/BannerConsumidor/BannerConsumidor";
import { DescripcionProceso } from "../components/DescripcionProceso/DescripcionProceso";
import { Footer } from "../components/Footer/Footer";
import { NavBar } from "../components/Navbar/NavBar";
import imag from "../assets/home/acciones2.webp";
import { FloatingButton } from "../components/FloatingButton/FloatingButton";

export function AccionesConstitucionalesPage(){
    return(
        <>
        <NavBar/>
        <BannerConsumidor/>
        <DescripcionProceso descripcion={
            <div>
            <p>
            Nuestra Empresa ofrece dentro de su portafolio de servicios legales, la consultoría y 
            proyección de acciones constitucionales, que permitan proteger cada uno de sus derechos 
            fundamentales, amparados en la Constitución Política, relacionados con:
            </p>
            <div class='container'>
                <ul>
                    <li>Acciones de tutela.</li>
                    <li>Derechos de petición</li>
                    <li>Acciones Populares</li>
                    <li>Protección de derechos fundamentales – acciones de grupo</li>
                    <li>Acción de tutela contra providencia judicial</li>
                    <li>Y demás acciones que impliquen la reclamación legal o de forma que permitan evitar perjuicios.</li>
                </ul>
            </div>
        </div>
        } imagen={imag}/>
        <FloatingButton/>
        <Footer/>
        </>
    )
}