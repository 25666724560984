import '../../Consumidor.css';
import { Container, Row, Col } from 'react-bootstrap';
import derBanner from "../../assets/home/penal.webp";

export const BannerPenal = () =>{
    return(
        <section className="bannerProceso" id="transito">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} xl={6} className="texto" style={{ marginRight: '50px' }}>
                        <span className="tagline"></span>
                        <h1>{'Derecho Penal'}</h1>
                    </Col>
                    <Col xs={12} md={6} xl={5} className="d-flex justify-content-end">
                        <img src={derBanner} alt="Header Img" style={{ maxWidth: '110%', height: 'auto' , borderRadius: '50px', flex: '1'}} className="img1banner"/>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}