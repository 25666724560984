import { Container, Row, Col } from "react-bootstrap";
import derBanner from "../../assets/home/about.jpeg";
import '../../About.css';
export const BannerAbout = () =>{
    return (
        <section className="bannerAbout" id="about">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} xl={6} className="texto" style={{ marginRight: '50px' }}>
                        <span className="tagline"></span>
                        <h1>{'¿Quienes somos?'}</h1>
                        <p>Contamos con más 6 de años de experiencia en el mercado. Con resultados exitosos y clientes satisfechos.</p>
                    </Col>
                    <Col xs={12} md={6} xl={5} className="d-flex justify-content-end">
                        <img src={derBanner} alt="Header Img" style={{ maxWidth: '110%', height: 'auto' , borderRadius: '50px', flex: '1'}} className="img1banner"/>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
