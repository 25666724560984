import { NavBar } from "../components/Navbar/NavBar";
import { Footer } from "../components/Footer/Footer";
import { BannerFinanciero } from "../components/BannerFinanciero/BannerFinanciero";
import '../Financiero.css'
import { DescripcionProceso } from "../components/DescripcionProceso/DescripcionProceso";
import imag from "../assets/home/financiero2.webp";
import { FloatingButton } from "../components/FloatingButton/FloatingButton";

export function FinancieroPage(){
    return (
        <>
        <div className="App">
            <NavBar/>
            <BannerFinanciero/>
            <DescripcionProceso descripcion={
                <div>
                <p>
                Nuestra Empresa ofrece dentro de su portafolio de servicios legales empresariales, 
                así como a personas naturales que presente algún conflicto derivado de alguna 
                trasgresión dentro del sistema financiero o como consumidor, las siguientes:
                </p>
                <ul>
                    <li>Acciones de protección al consumidor financiero.</li>
                    <li>Acciones de reliquidación de obligaciones financieras</li>
                    <li>Acciones derivadas obligación de cooperativas de ahorro y crédito.</li>
                    <li>Materialización de garantías, derecho a retracto y acciones de protección.</li>
                    <li>Estudio de prescripción y caducidad, derivada de títulos valores.</li>
                    <li>Asesoría legal empresarial y financiera al sector real y cooperativo.</li>
                    <li>Las demás derivadas de relaciones comerciales y naturales, producidas de bienes y servicios o dineros concedidos a títulos de préstamo.</li>
                </ul>
            </div>
            } imagen={imag}/>
            <FloatingButton/>
            <Footer />
        </div>
        </>
    )
}