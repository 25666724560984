export const FloatingButton = () =>{

    const openExternalLinkWhatsapp = () => {
        window.open("https://linktr.ee/whatsappblg");
      };
    const openExternalLinkFacebook = () => {
    window.open("https://www.facebook.com/businesslegalgroup/");
    };
    const openExternalLinkInstagram = () => {
    window.open("https://www.instagram.com/businesslegalgroup?igsh=cDdueDI4MHk2NG4w");
    };

    return (
        <>
        <button className="rounded-circle-whatsapp" onClick={openExternalLinkWhatsapp}><ion-icon name="logo-whatsapp"></ion-icon></button>
        <button className="rounded-circle-facebook" onClick={openExternalLinkFacebook}><ion-icon name="logo-facebook"></ion-icon></button>
        <button className="rounded-circle-instagram" onClick={openExternalLinkInstagram}><ion-icon name="logo-instagram"></ion-icon></button>
        </>
    )
}