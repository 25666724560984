import { Container, Row, Col } from "react-bootstrap";
import imagen from "../../assets/home/about3.jpeg";
import imagen2 from "../../assets/home/about2.jpeg";
export const MisionyVision = () =>{
    return (
        <Container className="contenedorMisionVision">
          <Row>
            <Col xs={12} md={6} xl={6}>
            <h3>Nuestra Experiencia</h3>
              <p style={{textAlign: 'justify'}}>Somos más que una firma de abogados; somos defensores apasionados de la justicia, asesores confiables 
                y aliados inquebrantables de nuestros clientes. Desde nuestra fundación en el 2018 , hemos estado 
                comprometidos con la excelencia legal y la defensa incansable de los derechos y los intereses de aquellos a 
                quienes servimos.<br/>

                <br/>
                
                En el corazón de nuestra firma se encuentra nuestro equipo de abogados talentosos y dedicados que comparten 
                una visión común: proporcionar asesoramiento legal de primera clase y soluciones efectivas para nuestros 
                clientes en cada etapa del proceso legal. Con una combinación de experiencia, conocimiento especializado y 
                un enfoque centrado en el cliente, abordamos cada caso con integridad, dedicación y determinación.<br/>
                </p>
            </Col>
            <Col xs={12} md={6} xl={6}>
            <img src={imagen} style={{ maxWidth: '95%', height: 'auto' , borderRadius: '50px', flex: '1', marginLeft: '15px'}} className="imgdescripcion"/>
            </Col>
          </Row>
          <Row style={{ marginTop: '30px'}}>
            <Col xs={12} md={6} xl={6}>
              <img src={imagen2} style={{ maxWidth: '92%', height: 'auto' , borderRadius: '50px', flex: '1', marginTop: '20px'}} className="imgdescripcion"/>
            </Col>
            <Col>
            <p style={{textAlign: 'justify'}}><br/>

                Lo que nos distingue es nuestro compromiso inquebrantable con la excelencia y la ética profesional. Cada 
                cliente y cada caso son tratados con el más alto nivel de atención y cuidado, y nos esforzamos por obtener 
                resultados favorables de manera eficiente y efectiva. Desde la representación en litigios complejos hasta la 
                asesoría en transacciones comerciales delicadas, nos dedicamos a proteger los derechos y los intereses de 
                nuestros clientes con pasión y diligencia.<br/>

                <br/>

                En Business financial and legal group sas , no solo ofrecemos servicios legales; construimos relaciones 
                sólidas y duraderas con nuestros clientes, basadas en la confianza, la transparencia y el respeto mutuo. 
                Nuestra dedicación a la excelencia y el compromiso con la justicia nos impulsan en cada caso que aceptamos, 
                y estamos comprometidos a seguir siendo líderes en nuestra industria y a defender los principios fundamentales 
                de la ley.<br/>

                <br/>

                Únete a nosotros en nuestro viaje legal y descubre el poder transformador de la representación legal con Business financial and legal group sas.
            </p>
            </Col>
          </Row>
          <Row style={{marginTop: '30px'}}>
            <Col>
              <h3>Misión</h3>
              <p style={{textAlign: 'justify'}}>Suministrar a nuestros clientes, un servicio de asesoramiento y representación jurídica, en cada una de las áreas del derecho, a través de profesionales especializados que propondrán soluciones eficaces y  personalizadas, acompañando en la toma de decisiones con fundamento en la ley, la doctrina y la jurisprudencia, sumada a nuestra experiencia, con el objetivo final de lograr la satisfacción total de sus necesidades dotándoles de seguridad jurídica frente al requerimiento e inquietud que se presente.</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Visión</h3>
              <p style={{textAlign: 'justify'}}>Para el 2027 buscamos posicionarnos como una de las firmas especializadas a nivel nacional que brinde un soporte legal, jurídico y de acompañamiento en las diferentes áreas del derecho, siendo la firma líder en prestar servicios legales reconocida por sus altos estándares de calidad, profesionalismo y seriedad respecto de cada situación manifestada por nuestro cliente o empresa que requiera nuestros servicios.</p>
            </Col>
          </Row>
        </Container>
      );
}