import { NavBar } from "../components/Navbar/NavBar";
import { Footer } from "../components/Footer/Footer";
import { BannerRecursos } from "../components/BannerRecursos/BannerRecursos";
import { DescripcionProceso } from "../components/DescripcionProceso/DescripcionProceso";
import { FloatingButton } from "../components/FloatingButton/FloatingButton";
import imag from "../assets/home/recursos2.webp";

export function RecursosPage(){
    return (
        <>
        <div className="App">
            <NavBar/>
            <BannerRecursos/>
            <DescripcionProceso descripcion={
                <div>
                <p>
                Nuestra Empresa ofrece dentro de su portafolio de servicios legales, la proyección 
                y presentación de recursos ordinarios y extraordinarios, partiendo de estrategias 
                jurídicas consistentes para hacer frente al requerimiento legal respectivo, tales como:
                </p>
                <ul>
                    <li>Recurso de reposición.</li>
                    <li>Recurso de reposición y en subsidio el de apelación.</li>
                    <li>Recurso de queja.</li>
                    <li>Recurso de revisión.</li>
                    <li>Recurso de Casación.</li>
                    <li>Recurso de Suplica.</li>
                </ul>
            </div>
            } imagen={imag}/>
            <FloatingButton />
            <Footer />
        </div>
        </>
    )
}