import { NavBar } from "../components/Navbar/NavBar";
import { Footer } from "../components/Footer/Footer";
import '../Laboral.css';
import { BannerLaboral } from "../components/BannerLaboral/BannerLaboral";
import { DescripcionProceso } from "../components/DescripcionProceso/DescripcionProceso";
import { FloatingButton } from "../components/FloatingButton/FloatingButton";
import imag from "../assets/home/laboral2.webp";

export function LaboralPage(){
    return (
        <>
        <div className="App">
            <NavBar/>
            <BannerLaboral/>
            <DescripcionProceso descripcion={
            <div>
                <p>
                Nuestra Empresa ofrece dentro de su portafolio de servicios, la asesoría y 
                consultoría legal tanto para trabajadores como para empleadores, 
                atendiendo las diferentes necesidades que puedan presentarse 
                en ejercicio de la relación laboral, tales como:
                </p>
                <ul>
                    <li>Creación e implementación de contratos de trabajo, con base a la necesidad del ejercicio del objeto social de la Empresa.</li>
                    <li>Proyección y actualización del reglamento interno de trabajo, de la mano con las disposiciones normativas existente.</li>
                    <li>Asesoría integral en derecho colectivo, sindical y negociación de pliego de peticiones.</li>
                    <li>Asesoría en terminación de contratos laborales, bajo sus diversas modalidades, (Con o sin justa causa, estabilidad laboral reforzada, mujer gestante o en licencia)</li>
                    <li>Reclamación de pago de prestación sociales, y de indemnizaciones y sanciones (artículo 64 – 65 del Código Sustantivo del Trabajo) (ley 50 de 1990)</li>
                    <li>Las demás que se relaciones con el ejercicio de un contrato laboral vigente</li>
                </ul>
            </div>
            } imagen={imag}/>
            <FloatingButton/>
            <Footer />
        </div>
        </>
    )
}