import { NavBar } from "../components/Navbar/NavBar";
import { Footer } from "../components/Footer/Footer";
import { BannerPenal } from "../components/BannerPenal/BannerPenal";
import '../Transito.css';
import { DescripcionProceso } from "../components/DescripcionProceso/DescripcionProceso";
import { FloatingButton } from "../components/FloatingButton/FloatingButton";
import imag from "../assets/home/penal2.webp";

export function PenalPage(){
    return (
        <>
        <div className="App">
            <NavBar/>
            <BannerPenal/>
            <DescripcionProceso descripcion={
                <div>
                <p>
                Nuestra Empresa ofrece dentro de su portafolio de servicios legales, 
                la representación proyección y asesoría legal, dentro de un proceso penal 
                derivado de una conducta punible, descrita dentro del código Penal, para lo 
                cual estamos prestos asumir la defensa de su caso o en su defecto a interponer 
                las acciones y denuncias respectivas, ante conductas que afecten su integridad personal, tales como:
                </p>
                <ul>
                    <li>Proyección de denuncias y querellas.</li>
                    <li>Representación en audiencias ante fiscalía, juez de control de garantías o juez de conocimiento.</li>
                    <li>Recurso de queja.</li>
                    <li>Principio de oportunidad.</li>
                    <li>Incidente de reparación.</li>
                    <li>Las demás derivadas de un proceso penal.</li>
                </ul>
            </div>
            } imagen={imag}/>
            <FloatingButton/>
            <Footer />
        </div>
        </>
    )
}