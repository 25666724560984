import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { AboutPage } from './pages/AboutPage';
import { AccionesConstitucionalesPage } from './pages/AccionesConstitucionales';
import { AJEPage } from './pages/AJEPage';
import { PenalPage } from './pages/PenalPage';
import { LaboralPage } from './pages/LaboralPage';
import { CivilPage } from './pages/CivilPage';
import { FinancieroPage } from './pages/FinancieroPage';
import { RecursosPage } from './pages/RecursosPage';
import { TransitoPage } from './pages/TransitoPage';
import { ProcesosSICPage } from './pages/ProcesosSICPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage/>}/>
        <Route path='/quienes-somos' element={<AboutPage/>}/>
        <Route path='/acciones-constitucionales' element={<AccionesConstitucionalesPage/>}/>
        <Route path='/asesoramiento-juridico-empresarial' element={<AJEPage/>}></Route>
        <Route path='/derecho-penal' element={<PenalPage/>}></Route>
        <Route path='/derecho-laboral' element={<LaboralPage/>}></Route>
        <Route path='/derecho-civil-familia' element={<CivilPage/>}></Route>
        <Route path='/derecho-financiero' element={<FinancieroPage/>}></Route>
        <Route path='/recursos' element={<RecursosPage/>}></Route>
        <Route path='/derecho-transito-transporte' element={<TransitoPage/>}></Route>
        <Route path='/procesos-sic' element={<ProcesosSICPage/>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
