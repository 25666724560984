import { NavBar } from "../components/Navbar/NavBar";
import { DescripcionProceso } from "../components/DescripcionProceso/DescripcionProceso";
import { Footer } from "../components/Footer/Footer";
import { BannerTransito } from "../components/BannerTransito/BannerTransito";
import { FloatingButton } from "../components/FloatingButton/FloatingButton";
import imag from "../assets/home/transito2.jpeg";
export function TransitoPage(){
    return (
        <>
        <div className="App">
            <NavBar/>
            <BannerTransito/>
            <DescripcionProceso descripcion={
                <div>
                <p>
                Nuestra Empresa ofrece dentro de su portafolio de servicios legales en favor de los conductores activos,
                 que sean inmersos en contravenciones, derivadas de la imposición de orden de comparendo, tales como:
                </p>
                <ul>
                    <li>Impugnación de orden de comparendo.</li>
                    <li>Proyección y presentación de acciones constitucionales ante procedimientos erróneos 
                        dentro de la contravención presentada.</li>
                    <li>Presentación de recurso y representación jurídica dentro de los tramite que se 
                        surtan ante el órgano de tránsito competente.</li>
                    <li>Interposición de demanda de nulidad y restablecimiento del derecho contra 
                        resolución y/o acto administrativo que declare contraventor al conductor.</li>
                </ul>
            </div>
            } imagen={imag}/>
            <FloatingButton/>
            <Footer />
        </div>
        </>
    )
}