import { Container, Row, Col } from "react-bootstrap";
import derBanner from "../../assets/home/img1banner.webp";

export const Banner = () => {
    return (
        <section className="banner" id="home">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} xl={6} className="texto" style={{ marginRight: '50px' }}>
                        <span className="tagline">Somos</span>
                        <h1>{'Business Legal Group'}</h1>
                        <p>Somos más que una firma de abogados; somos defensores apasionados de la justicia, asesores confiables y aliados 
                        inquebrantables de nuestros clientes.
                        </p>
                    </Col>
                    <Col xs={12} md={6} xl={5} className="d-flex justify-content-end">
                        <img src={derBanner} alt="Header Img" style={{ maxWidth: '110%', height: 'auto' , borderRadius: '50px', flex: '1'}} className="img1banner"/>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}