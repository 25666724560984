import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
export const NavBar = () => {
    return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">
            <span>BUSINESS LEGAL GROUP</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <Nav.Link as={Link} to="/">INICIO</Nav.Link>
            <Nav.Link as={Link} to="/quienes-somos">¿QUIENES SOMOS?</Nav.Link>
            <NavDropdown title="PROCESOS" id="basic-nav-dropdown" className="custom-dropdown">
              <NavDropdown.Item as={Link} to="/acciones-constitucionales">
                ACCIONES CONSTITUCIONALES
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/asesoramiento-juridico-empresarial">
                ASESORAMIENTO JURIDICO EMPRESARIAL
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/derecho-transito-transporte">
                DERECHO DE TRANSITO Y TRANSPORTE
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/derecho-penal">
                DERECHO PENAL
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/derecho-laboral">
                DERECHO LABORAL
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/derecho-financiero">
                DERECHO FINANCIERO
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/derecho-civil-familia">
                DERECHO CIVIL Y DE FAMILIA
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/recursos">
                RECURSOS
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/procesos-sic">
                PROCESOS SIC
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="#link">CONTACTO</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    );
}