import { BannerAJE } from "../components/BannerAJE/BannerAJE";
import { DescripcionProceso } from "../components/DescripcionProceso/DescripcionProceso";
import { FloatingButton } from "../components/FloatingButton/FloatingButton";
import { Footer } from "../components/Footer/Footer";
import { NavBar } from "../components/Navbar/NavBar";
import imag from "../assets/home/AJE2.webp"

export function AJEPage(){
    return(
        <>
        <NavBar/>
        <BannerAJE/>
        <DescripcionProceso descripcion={
            <div>
            <p>
            Nuestra Empresa ofrece dentro de su portafolio de servicios legales empresariales,
             en busca de solventar las necesidades y desafíos propuestos no solo dentro del 
             mercado del ejercicio del objeto social, sino derivado de las normas que el gobierno 
             nacional profiera que afecten el desarrollo empresarial, así como:
            </p>
            <ul>
                <li>Creación de Empresas.</li>
                <li>Representación legal.</li>
                <li>Revisión de contratos y emisión de conceptos.</li>
                <li>Requerimientos UGPP y DIAN y de más entidades de control estalas.</li>
                <li>Las demás que surjan dentro del ejercicio empresarial.</li>
            </ul>
        </div>
        } imagen={imag}/>
        <FloatingButton/>
        <Footer/>
        </>
    )
}