import { NavBar } from "../components/Navbar/NavBar";
import { DescripcionProceso } from "../components/DescripcionProceso/DescripcionProceso";
import { BannerSIC } from "../components/BannerSIC/BannerSIC";
import { FloatingButton } from "../components/FloatingButton/FloatingButton";
import { Footer } from "../components/Footer/Footer";
import imag from "../assets/home/sic2.jpeg";

export function ProcesosSICPage(){
    return(
        <>
        <NavBar/>
        <BannerSIC/>
        <DescripcionProceso descripcion={
            <div>
            <p>
            El derecho del consumidor es un conjunto de normas y regulaciones diseñadas para proteger 
            los derechos e intereses de los consumidores en las transacciones comerciales. Estas 
            leyes buscan equilibrar la relación entre los consumidores y los proveedores de bienes y 
            servicios, asegurando que los consumidores tengan acceso a información clara y precisa sobre 
            los productos que están adquiriendo, así como garantizando la calidad y seguridad de dichos productos. 
            Entre los derechos básicos del consumidor se encuentran:
            </p>
            <ul>
                <li>Derecho a la información: Los consumidores tienen derecho a recibir información clara y completa sobre los productos y servicios que están adquiriendo, incluyendo precios, características, condiciones de uso, y cualquier otro detalle relevante.</li>
                <li>Derecho a la seguridad: Los productos y servicios ofrecidos en el mercado deben ser seguros para su uso, y los consumidores tienen derecho a exigir medidas de seguridad adecuadas.</li>
                <li>Derecho a elegir: Los consumidores tienen derecho a elegir libremente entre diferentes productos y servicios, sin ser objeto de prácticas comerciales coercitivas o engañosas.</li>
                <li>Derecho a la protección contra la publicidad engañosa o fraudulenta.</li>
            </ul>
        </div>
        } imagen={imag}/>
        <FloatingButton/>
        <Footer/>
        </>
    )
}