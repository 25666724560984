import { NavBar } from "../components/Navbar/NavBar";
import { Footer } from "../components/Footer/Footer";
import '../Laboral.css';
import { BannerCivil } from "../components/BannerCivil/BannerCivil";
import { DescripcionProceso } from "../components/DescripcionProceso/DescripcionProceso";
import { FloatingButton } from "../components/FloatingButton/FloatingButton";
import imag from "../assets/home/civil2.webp";

export function CivilPage(){
    return (
        <>
        <div className="App">
            <NavBar/>
            <BannerCivil/>
            <DescripcionProceso descripcion={
                <div>
                <p>
                Nuestra Empresa ofrece dentro de su portafolio de servicios 
                legales empresariales, así como a personas naturales que presente 
                algún conflicto derivado de alguna contravención, los siguientes servicios:
                </p>
                <ul>
                    <li>Constitución en mora ante el incumplimiento de una obligación.</li>
                    <li>Herencias y Sucesiones: Manejo de asuntos relacionados con la 
                        planificación patrimonial, testamentos, sucesiones y disputas herenciales, 
                        así como la compraventa de derechos herenciales.</li>
                    <li>Asesoría y seguimiento judicial ante acciones donde se ostente la calidad de demandante o demandado ante la jurisdicción civil.</li>
                    <li>Elaboración de contratos típico y/o atípicos regulados por la legislación civil.</li>
                    <li>Asesoría en temas de familia, cesación de efectos civiles, liquidación patrimonial, Capitulaciones</li>
                    <li>Consulta de bienes muebles e inmueble, cuentas bancarias de personas naturales</li>
                    <li>Seguimiento judicial en responsabilidad contractual y extra contractual</li>
                    <li>Responsabilidad Civil: Representación en casos de daños y perjuicios, accidentes y otras formas de responsabilidad civil.</li>
                    <li>Títulos Valores y Fiducias: Asesoramiento en emisión, negociación y litigios relacionados con letras, pagarés, cheques, acciones, bonos y otros instrumentos financieros.</li>
                    <li>Las demás relacionadas con controversias</li>
                </ul>
            </div>
            } imagen={imag}/>
            <FloatingButton/>
            <Footer />
        </div>
        </>
    )
}