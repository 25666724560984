import { NavBar } from "../components/Navbar/NavBar";
import '../About.css';
import { BannerAbout } from "../components/BannerAbout/BannerAbout";
import { Footer } from "../components/Footer/Footer";
import { MisionyVision } from "../components/MisionyVision/MisionyVision";
import { FloatingButton } from "../components/FloatingButton/FloatingButton";
export function AboutPage(){
    return (
        <>
            <NavBar/>
            <BannerAbout/>
            <MisionyVision/>
            <FloatingButton/>
            <Footer/>
        </>
    )
}