import React from "react";
import {Col, Row} from "react-bootstrap";

export function DescripcionProceso({descripcion, imagen}){
    return (
        <div className="card">
            <div className="card-body">
                <Row>
                    <Col xs={12} md={6} xl={6} className="descripcion">
                        <p className="card-text" style={{ lineHeight: '1.9'}}>{descripcion}</p>                   
                    </Col>
                    <Col xs={12} md={6} xl={6} className="d-flex justify-content-end">
                        <img src={imagen} style={{ maxWidth: '80%', height: 'auto' , borderRadius: '50px', flex: '1'}} className="imgdescripcion"/>
                    </Col>
                </Row>
            </div>
        </div>
    )
}